import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { Box, Image, Link } from '@rushplay/common'
import { withTranslate } from '@rushplay/i18n'

import sealImg from '../../static/images/gcb.png'

import ApgSeal from './apg-seal'

const LicenseImage = styled.img`
  max-height: 45px;
  margin-left: 6px;
  margin-right: 6px;
`

function LicenseLogos(props) {
  return (
    <Box
      display="inline-flex"
      justifyContent="inherit"
      alignItems="center"
      flexWrap="inherit"
    >
      <Box
        as="a"
        display="flex"
        href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbWw2T0dsb1VubFZSRXhDU0VORGVHazFTRUZsZDBFOVBTSXNJblpoYkhWbElqb2lWM3BqYjBacFNsRXdXWFp3TlVreGIweDFURmd3UVQwOUlpd2liV0ZqSWpvaU4yWTJPVGszTldOaU9UWmxOVFZoT1RVNFptVXdNREEwWmpnME1UazVPR1F6TURVeU5EQTFORFpsTkRBeFpXUXlNVFUzTVRoaFlqZzBaVGRoTlRaa01pSXNJblJoWnlJNklpSjk="
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={sealImg} height="40px" />
      </Box>
      {props.licenses.showAppSeal && (
        <Box size="50px" display="inline-block" verticalAlign="bottom" mx="8px">
          <ApgSeal />
        </Box>
      )}

      {R.map(
        (license) =>
          license.url ? (
            <Link key={license.src} to={license.url}>
              <LicenseImage src={props.translate(license.src)} />
            </Link>
          ) : (
            <LicenseImage
              key={license.src}
              src={props.translate(license.src)}
            />
          ),
        props.licenses.list
      )}

      {props.license === 'mga' && (
        <Link to="https://www.gamblingtherapy.org/en" target="_blank">
          <Image
            size="fixed"
            height={45}
            src={props.translate('gamblingtherapy-logo')}
            alt="Gambling Therapy"
          />
        </Link>
      )}
    </Box>
  )
}

export default withTranslate(LicenseLogos)

LicenseLogos.propTypes = {
  license: PropTypes.string.isRequired,
  licenses: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
}
