import * as R from 'ramda'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'
import React from 'react'
import {
  IndexRedirect,
  IndexRoute,
  Redirect,
  Route,
  Router as ReactRouter,
  browserHistory,
} from 'react-router'

import * as I18n from '@rushplay/i18n'
import * as locks from '@rushplay/compliance/locks'
import * as supportChat from '@rushplay/support-chat'
import * as ShuftiPro from '@rushplay/shufti-pro'
import {
  getConsent,
  getEmail,
  isPhoneVerificationRequired,
  isSessionActive,
} from '@rushplay/session'

import * as App from '../store/app'
import SignupContainer, { PhoneVerification } from '../sign-up'
import bossFight from '../boss-fight'
import developmentOptions from '../development-options'
import triggers from '../store/triggers/'
import withoutLanguage from '../util/path-without-language'
import {
  CasinoHomeContainer,
  CasinoRootContainer,
  CategoryContainer,
  GameContainer,
} from '../casino'
import { HorseRacing } from '../horse-racing'
import { PageSpinner } from '../common/spinner'

import AppContainer from './app-container'
import LogoutContainer from './logout-container'
import NotFound from './not-found'
import { MyIdentity } from './user-dashboard/identity'

function PageLoading(props) {
  const i18n = I18n.useI18n()
  if (props.error) {
    return (
      <div>
        {i18n.translate('page-loading.error')}
        <button onClick={props.retry}>
          {i18n.translate('page-loading.retry')}
        </button>
      </div>
    )
  } else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <PageSpinner />
  } else {
    // When the loader has just started
    return null
  }
}

PageLoading.propTypes = {
  error: PropTypes.object,
  retry: PropTypes.func,
  pastDelay: PropTypes.bool,
}

const CampaignOverlayContainer = Loadable({
  loader: () => import('./region/campaign-overlay-container'),
  loading: PageLoading,
})

const CreateNewPasswordConfirmation = Loadable({
  loader: () => import('./forgot-password/create-new-password-confirmation'),
  loading: PageLoading,
})

const CreateNewPasswordContainer = Loadable({
  loader: () => import('./forgot-password/create-new-password-container'),
  loading: PageLoading,
})

const PaymentMethodsPage = Loadable({
  loader: () => import('../payment-methods.js'),
  loading: PageLoading,
})

const Deposit = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const Deposit = loaded.Deposit
    return <Deposit {...props} />
  },
  loading: PageLoading,
})

const EditAvatar = Loadable({
  loader: () => import('./user-dashboard/edit-avatar'),
  loading: PageLoading,
})

const EditDetails = Loadable({
  loader: () => import('./user-dashboard/edit-details'),
  loading: PageLoading,
})

const EditPassword = Loadable({
  loader: () => import('./user-dashboard/edit-password'),
  loading: PageLoading,
})

const EmbeddedSignUp = Loadable({
  loader: () => import('../embedded-sign-up'),
  render(loaded, props) {
    const EmbeddedSignUp = loaded.EmbeddedSignUp
    return <EmbeddedSignUp {...props} />
  },
  loading: PageLoading,
})

const GamblingHistoryContainer = Loadable({
  loader: () => import('../gambling-history/gambling-history-container'),
  loading: PageLoading,
})

const GamstopInfo = Loadable({
  loader: () => import('../limits/self-exclusion-success'),
  loading: PageLoading,
})

const HowToPlayDetailContainer = Loadable({
  loader: () => import('../how-to-play/how-to-play-detail-container.js'),
  loading: PageLoading,
})

const HowToPlayHomeContainer = Loadable({
  loader: () => import('../how-to-play/how-to-play-home-container.js'),
  loading: PageLoading,
})

const InventoryContainer = Loadable({
  loader: () => import('../inventory'),
  loading: PageLoading,
})

const LandingPage = Loadable({
  loader: () => import('./landing-page.js'),
  loading: PageLoading,
})

const Limits = Loadable({
  loader: () => import('../limits/limits'),
  loading: PageLoading,
})

const LiveCasinoContainer = Loadable({
  loader: () => import('../live-casino'),
  loading: PageLoading,
})

const LockPage = Loadable({
  loader: () => import('./locks'),
  loading: PageLoading,
})

const LogInDetails = Loadable({
  loader: () => import('../sign-up/log-in-details'),
  loading: PageLoading,
})

const LoginPage = Loadable({
  loader: () => import('./login-page'),
  loading: PageLoading,
})

const LoginTimeLimitsContainer = Loadable({
  loader: () => import('../limits/login-time-limits-container'),
  render(loaded, props) {
    const LoginTimeLimitsContainer = loaded.LoginTimeLimitsContainer
    return <LoginTimeLimitsContainer {...props} />
  },
  loading: PageLoading,
})

const MoneyLimits = Loadable({
  loader: () => import('../limits/money-limits'),
  render(loaded, props) {
    const MoneyLimits = loaded.MoneyLimits
    return <MoneyLimits {...props} />
  },
  loading: PageLoading,
})

const NetDepositInformationPopup = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const NetDepositInformationPopup = loaded.NetDepositInformationPopup
    return <NetDepositInformationPopup {...props} />
  },
  loading: PageLoading,
})

const OnboardingContainer = Loadable({
  loader: () => import('./onboarding/onboarding-container'),
  loading: PageLoading,
})

const PartnerContainer = Loadable({
  loader: () => import('../partner/partner-container'),
  loading: PageLoading,
})

const PayNPlayTransaction = Loadable({
  loader: () => import('../wallet/pay-n-play-transaction'),
  loading: PageLoading,
})

const PlaySmartContainer = Loadable({
  loader: () => import('../play-smart/play-smart-container'),
  loading: PageLoading,
})

const PrivacyPolicyUpdatesContainer = Loadable({
  loader: () => import('../privacy-policy-updates'),
  loading: PageLoading,
})

const PromotionsContainer = Loadable({
  loader: () => import('../promotions'),
  loading: PageLoading,
})

const PromotionsDetailContainer = Loadable({
  loader: () => import('../promotions/promotions-detail-container'),
  loading: PageLoading,
})

const RealityCheckContainer = Loadable({
  loader: () => import('../limits/reality-check-container'),
  loading: PageLoading,
})

const ReceiptDetails = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const ReceiptDetails = loaded.ReceiptDetails
    return <ReceiptDetails {...props} />
  },
  loading: PageLoading,
})

const ReceiptSummary = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const ReceiptSummary = loaded.ReceiptSummary
    return <ReceiptSummary {...props} />
  },
  loading: PageLoading,
})

const RegionContainer = Loadable({
  loader: () => import('./region/region-container'),
  loading: PageLoading,
})

const RegionLoggedOutContainer = Loadable({
  loader: () => import('./region/region-logged-out-container'),
  loading: PageLoading,
})

const RegionPopupsContainer = Loadable({
  loader: () => import('./region/region-popups-container'),
  loading: PageLoading,
})

const RegionPreloader = Loadable({
  loader: () => import('./region/region-preloader'),
  loading: PageLoading,
})

const ResetPasswordConfirmation = Loadable({
  loader: () => import('./forgot-password/reset-password-confirmation'),
  loading: PageLoading,
})

const ResetPasswordContainer = Loadable({
  loader: () => import('./forgot-password/reset-password-container'),
  loading: PageLoading,
})

const RubyStoreContainer = Loadable({
  loader: () => import('../ruby-store'),
  loading: PageLoading,
})

const SelfExclusionContainer = Loadable({
  loader: () => import('../limits/self-exclusion-container'),
  loading: PageLoading,
})

// const Sportsbook = Loadable({
//   loader: () => import('../sportsbook'),
//   render(loaded, props) {
//     const Sportsbook = loaded.Sportsbook
//     return <Sportsbook {...props} />
//   },
//   loading: PageLoading,
// })

// TODO: Remove this whenever Betby is done testing and we move back into Gamer integration..
const Sportsbook = Loadable({
  loader: () => import('../sportsbook'),
  render(loaded, props) {
    const Sportsbook = loaded.Sportsbook
    return <Sportsbook {...props} />
  },
  loading: PageLoading,
})

const StaticPageContainer = Loadable({
  loader: () => import('./static/static-page-container'),
  loading: PageLoading,
})

const TimeOutContainer = Loadable({
  loader: () => import('../limits/time-out-container'),
  loading: PageLoading,
})

const UserDashboardContainer = Loadable({
  loader: () => import('./user-dashboard/user-dashboard-container'),
  loading: PageLoading,
})

const Wallet = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const Wallet = loaded.Wallet
    return <Wallet {...props} />
  },
  loading: PageLoading,
})

const Withdraw = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const Withdraw = loaded.Withdraw
    return <Withdraw {...props} />
  },
  loading: PageLoading,
})

const WorldsContainer = Loadable({
  loader: () => import('./worlds/worlds-container'),
  loading: PageLoading,
})

/**
 * Check if given path represents a page that can be displayed to locked player.
 * @param {string} currentPath
 * @returns {boolean}
 */
function isValidLockPage(currentPath) {
  return R.any(
    (path) => currentPath.match(path),
    [
      '/about-us',
      '/locks',
      '/logout',
      '/phone-verification',
      '/privacy-policy-updates',
      '/register',
      '/dashboard',
      '/dashboard/limits',
      '/dashboard/limits/money-limits',
      '/dashboard/my-identity',
      '/terms-conditions',
      '/terms-conditions-sport',
      '/bonus-terms',
      '/responsible-gaming',
    ]
  )
}

function createHandleSupportChatLink(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }

    const newPath = withoutLanguage(args.nextState.location.pathname)
    const prevPath = args.prevState.location.pathname

    if (newPath === '/support-chat') {
      store.dispatch(supportChat.setTabVisibility(true))
      args.replace(prevPath)
      return
    }

    return args
  }
}

function createRedirectToSignupType(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }

    const path = '/register/signup-type'
    const state = store.getState()
    const language = state.app.language ? `/${state.app.language}` : ''
    const countryCode = state.lookup.countryCode

    const currentPath = withoutLanguage(args.nextState.location.pathname)

    if (
      !isSessionActive(state.session) &&
      currentPath !== path &&
      countryCode === 'FI'
    ) {
      args.replace(`${language}${path}`)
      return
    }

    return args
  }
}

function createRedirectToPath(store, path) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const state = store.getState()
    const language = state.app.language ? `/${state.app.language}` : ''

    if (
      isSessionActive(state.session) &&
      !currentPath.match('/register/log-in-info') &&
      !currentPath.match('/register/success')
    ) {
      args.replace(`${language}${path}`)

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRedirectFromPopups(store) {
  return (args) => {
    const state = store.getState()
    const language = state.app.language ? `/${state.app.language}` : ''

    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const search = args.nextState.location.search.replace(
      /popup=login&|popup=login|popup=register&|popup=register/,
      ''
    )
    if (args.nextState.location.query.popup === 'register') {
      return args.replace(`${language}/register/create-account${search}`)
    }

    if (args.nextState.location.query.popup === 'login') {
      return args.replace(`${language}/${search}`)
    }

    return args
  }
}

function createRequirePhoneVerification(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const state = store.getState()
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const validPage = isValidLockPage(currentPath)

    if (isPhoneVerificationRequired(state.session)) {
      if (!validPage) {
        const language = state.app.language ? `/${state.app.language}` : ''
        args.replace(`${language}/phone-verification`)
      }

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRequirePlayerConsent(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const state = store.getState()
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const validPage = isValidLockPage(currentPath)

    if (isSessionActive(state.session) && !getConsent(state.session)) {
      if (!validPage) {
        const language = state.app.language ? `/${state.app.language}` : ''
        args.replace(`${language}/privacy-policy-updates`)
      }

      // stop callback chain here
      return
    }

    // TODO: it might not be the best place for this redirect
    if (
      isSessionActive(state.session) &&
      getConsent(state.session) &&
      currentPath.match('/privacy-policy-updates')
    ) {
      const language = state.app.language ? `/${state.app.language}` : ''
      args.replace(`${language}/`)

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

/*
 * Locks are conditions that require player’s attention right away and prevent
 * any application usage until desired action is taken.
 *
 * Previously locks were represented by arbitrary properties in session
 * response.
 *
 * Updated approach is to consolidate all of them in single place and simplify
 * creation and support of new locks.
 *
 * List of current locks is obtained via GET /my/locks and stored in Redux.
 * Lock types are unique and must be used as slugs for lock pages.
 */
function createRequireLockAction(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const state = store.getState()
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const validPage = isValidLockPage(currentPath)

    const currentLocks = locks.getLocks(state.locks)

    if (currentLocks.length > 0) {
      if (!validPage) {
        const language = state.app.language ? `/${state.app.language}` : ''
        const lock = R.head(currentLocks)
        args.replace(`${language}/locks/${lock}`)
      } else if (
        currentPath.match('/locks') &&
        R.none((lock) => currentPath.match(`/locks/${lock}`), currentLocks)
      ) {
        args.replace('/')
      }

      // stop callback chain here
      return
    } else if (currentPath.match('/locks/')) {
      args.replace('/')

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRequireOnboardingCompletion(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      //do nothing
      return
    }

    const state = store.getState()
    const onboarding = triggers.selectors.getByEvent(
      'onboarding',
      state.triggers
    )

    const currentPath = withoutLanguage(args.nextState.location.pathname)

    if (
      onboarding &&
      !currentPath.match('/onboarding') &&
      !currentPath.match('/dashboard/limits') &&
      !currentPath.match('register/log-in-info')
    ) {
      const language = state.app.language ? `/${state.app.language}` : ''
      args.replace(`${language}/onboarding`)

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRequireAuth(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      return
    }

    if (!isSessionActive(store.getState())) {
      // TODO: redirect to 404
      args.replace('/')
    }

    return args
  }
}

function createRequireAdditionalDetails(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }

    const state = store.getState()
    const email = getEmail(state.session)
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const authenticated = isSessionActive(state)
    const validPage = isValidLockPage(currentPath)

    if (!email && authenticated) {
      if (!validPage) {
        const previousStatePath = R.pathOr(
          '',
          ['prevState', 'location', 'pathname'],
          args
        )
        const languagePath = state.app.language ? `/${state.app.language}` : ''
        const url = previousStatePath.match('/register/log-in-info')
          ? previousStatePath
          : `${languagePath}/register/log-in-info`
        args.replace(url)
      }

      // stop callback chain here
      return
    }
    // continue executing callbacks
    return args
  }
}

function handleOnEnterArguments(nextState, replace) {
  return { nextState, replace }
}

function handleOnChangeArguments(prevState, nextState, replace) {
  return { prevState, nextState, replace }
}

export function createRoutes(store) {
  const handleSupportChatLink = createHandleSupportChatLink(store)
  const redirectFromPopups = createRedirectFromPopups(store)
  const redirectToAdventure = createRedirectToPath(store, '/adventure')
  const redirectToCasino = createRedirectToPath(store, '/casino')
  const redirectToSignupType = createRedirectToSignupType(store)
  const requirePlayerConsent = createRequirePlayerConsent(store)
  const requirePhoneVerification = createRequirePhoneVerification(store)
  const requireLockAction = createRequireLockAction(store)
  const requireAdditionalDetails = createRequireAdditionalDetails(store)
  const requireAuth = R.pipe(handleOnEnterArguments, createRequireAuth(store))
  const requireOnboardingCompletion = createRequireOnboardingCompletion(store)
  const isHorseRacing = App.getHorseRacingEnabled()

  const state = store.getState()
  const isDevelopmentEnvironment = state.app.config.environment !== 'production'
  const isShuftiEnabled = R.pathOr(
    'false',
    ['config', 'shufti', 'enabled'],
    state.app
  )

  return (
    <Route>
      <Redirect from="/(:language)/index.html" to="/(:language)" />

      <Route
        path="/(:language)/embed/registration"
        component={EmbeddedSignUp}
      />
      <Route
        path="/(:language)/callbacks/shufti-pro"
        component={ShuftiPro.Callback}
      />
      <Route
        component={AppContainer}
        path="/(:language)"
        onChange={R.pipe(
          handleOnChangeArguments,
          handleSupportChatLink,
          requirePhoneVerification,
          requireAdditionalDetails,
          requirePlayerConsent,
          requireLockAction
        )}
        onEnter={R.pipe(
          handleOnEnterArguments,
          redirectFromPopups,
          requirePhoneVerification,
          requireAdditionalDetails,
          requirePlayerConsent,
          requireLockAction
        )}
      >
        <IndexRoute
          component={LandingPage}
          onEnter={R.pipe(
            handleOnEnterArguments,
            redirectToSignupType,
            redirectToCasino
          )}
        />

        <Route component={PartnerContainer} path="partner/:id" />

        <Route component={Sportsbook} path="sports" />
        {isHorseRacing ? (
          <Route component={HorseRacing} path="horse-racing" />
        ) : null}

        <Route onEnter={requireAuth}>
          <Route component={Wallet} path="wallet">
            <IndexRoute component={Deposit} />
            <Route path="deposit" component={Deposit} />
            <Route path="withdraw" component={Withdraw} />
            <Route component={ReceiptSummary} path="receipts(/page/:page)">
              <Route component={NetDepositInformationPopup} path=":id" />
              <Route component={ReceiptDetails} path="receipt/:id" />
            </Route>
          </Route>
        </Route>

        <Route component={CasinoRootContainer} path="casino">
          <IndexRoute component={CasinoHomeContainer} />
          <Route component={CategoryContainer} path=":category" />
          <Route
            component={(props) => <GameContainer {...props} />}
            path=":category/:provider/:gameId(/:gameKey)"
          />
        </Route>

        <Route component={PromotionsDetailContainer} path="promotion/:terms" />
        <Route component={PromotionsContainer} path="promotions/:tag" />
        <Route component={PromotionsContainer} path="promotions" />

        <Route component={LiveCasinoContainer} path="live-casino(/:category)" />
        <Route component={PaymentMethodsPage} path="payment-methods" />

        <Route
          component={RubyStoreContainer}
          path="rubystore"
          onEnter={requireAuth}
        />
        <Route
          component={RegionPreloader}
          onEnter={R.pipe(requireAuth, requireOnboardingCompletion)}
        >
          <Route component={RegionContainer} path="adventure">
            <Route component={CampaignOverlayContainer} path="campaigns/:key" />
            <Route component={RegionPopupsContainer} path=":popupKey" />
          </Route>
        </Route>

        <Route
          component={Limits}
          path="dashboard/limits"
          onEnter={R.pipe(requireAuth)}
        >
          <IndexRedirect to="money-limits" />
          <Route
            component={MoneyLimits}
            path="money-limits"
            ignoreScrollBehavior
          />
          <Route
            component={GamstopInfo}
            path="self-exclusion-success/:license"
          />

          <Route component={RealityCheckContainer} path="reality-check" />
          <Route component={LoginTimeLimitsContainer} path="session-limits" />
          <Route component={TimeOutContainer} path="time-out" />
          <Route component={SelfExclusionContainer} path="self-exclusion" />
        </Route>

        <Redirect from="player-limits" to="money-limits" />

        <Route
          component={GamblingHistoryContainer}
          path="dashboard/history"
          onEnter={R.pipe(requireAuth)}
        />
        <Route
          component={EditPassword}
          path="dashboard/password"
          onEnter={requireAuth}
        />
        <Route
          component={EditDetails}
          path="dashboard/edit-details"
          onEnter={requireAuth}
        />
        <Route
          component={EditAvatar}
          path="dashboard/edit-avatar"
          onEnter={requireAuth}
        />
        {isShuftiEnabled && (
          <Route
            component={MyIdentity}
            path="dashboard/my-identity"
            onEnter={R.pipe(requireAuth)}
          />
        )}

        <Route
          component={UserDashboardContainer}
          path="dashboard"
          onEnter={requireAuth}
        />

        <Route component={ResetPasswordContainer} path="reset-password" />
        <Route
          component={ResetPasswordConfirmation}
          path="reset-password-confirmation"
        />
        <Route component={CreateNewPasswordContainer} path="password/:token" />
        <Route
          component={CreateNewPasswordConfirmation}
          path="create-new-password-confirmation"
        />
        <Route
          component={PhoneVerification}
          path="phone-verification(/:step)"
          onEnter={requireAuth}
        />
        <Route
          component={PrivacyPolicyUpdatesContainer}
          path="privacy-policy-updates"
          onEnter={requireAuth}
        />
        <Route
          component={InventoryContainer}
          path="treasure-chest(/:id)(/:secretId)"
          onEnter={requireAuth}
        />
        <Route component={WorldsContainer} path="worlds(/:worldKey)" />
        {bossFight('bossfight', requireAuth, store)}
        {isDevelopmentEnvironment && developmentOptions('magic')}
        <Route
          component={OnboardingContainer}
          path="onboarding"
          onEnter={requireAuth}
        />

        <Route component={LockPage} path="locks/:lock" onEnter={requireAuth} />

        <Route component={StaticPageContainer} path="bonus-terms" />
        <Route component={StaticPageContainer} path="about-us" />
        <Route component={StaticPageContainer} path="terms-conditions" />
        <Route component={StaticPageContainer} path="terms-conditions-sport" />
        <Route
          component={StaticPageContainer}
          path="terms-conditions-horse-racing"
        />
        <Route component={StaticPageContainer} path="privacy-policy" />
        <Redirect from="play-smart" to="responsible-gaming" />
        <Route component={PlaySmartContainer} path="responsible-gaming" />
        <Route
          path="help"
          component={() => {
            window.location.href = 'https://faq.casitabi.com/hc/ja'
            return null
          }}
        />
        <Route
          component={HowToPlayDetailContainer}
          path="how-to-play/:tutorial"
        />
        <Route component={HowToPlayHomeContainer} path="how-to-play" />

        <Route
          path="register"
          onEnter={R.pipe(handleOnEnterArguments, redirectToCasino)}
        >
          <IndexRoute component={SignupContainer} />
          <Route component={PayNPlayTransaction} path="deposit" />
          <Route component={LogInDetails} path="log-in-info(/:step)" />
          <Route component={SignupContainer} path=":step" />
        </Route>

        <Route
          component={RegionLoggedOutContainer}
          path="adventure-promo"
          onEnter={R.pipe(handleOnEnterArguments, redirectToAdventure)}
        />

        <Route
          component={LogoutContainer}
          path="logout"
          onEnter={requireAuth}
        />
        <Route
          component={LoginPage}
          path="login"
          onEnter={R.pipe(handleOnEnterArguments, redirectToCasino)}
        />
        <Route component={NotFound} path="*" />
      </Route>
    </Route>
  )
}

function Router({ history, routes }) {
  return <ReactRouter history={history} routes={routes} />
}

Router.defaultProps = { history: browserHistory }

Router.propTypes = {
  // TODO: add shape
  history: PropTypes.object,
  routes: PropTypes.element.isRequired,
}

export default Router
